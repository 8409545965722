<template>
  <item-card>
    <template v-slot:title>
      <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
        Apakškategorijas
      </h3>

    </template>

    <template v-slot:content>
        <template v-if="categories">
            <template v-for="category in categories" :key="category.id">
                <SingleSimpleCategory :category="category" />
            </template>
        </template>
    </template>
  </item-card>
</template>

<script>
import ItemCard from "@/components/Components/ItemCard"
import SingleSimpleCategory from "@/components/Settings/Category/SingleSimpleCategory"

export default {
    name: "CategorySubcategories",
    components: {
        ItemCard,
        SingleSimpleCategory,
    },
    props: ['categories'],
}
</script>

<style>

</style>